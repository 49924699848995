import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from "../../config";
import loadable from "@loadable/component";
import Disclaimer from "../components/disclaimer/disclaimer";
import {
    adDisplayDelay,
    delayMultiplicationFactor,
    formatDate,
    googleScriptLoadingDelay,
    isBrowser,
    shuffleArray
} from "../utils/articleUtil";
import {orderBy as _orderBy} from 'lodash';
import {isMobile} from "react-device-detect";
import {Image} from "react-datocms";
import Helmet from "react-helmet";
import consts from "../constants/consts";
import Navigate from "../components/navigate";
import Accordion from "../components/faq";
import '../resources/source/stylesheets/components/_article.scss';

const ArticlePopularPosts = loadable(() => import("../components/popularPosts/articlePopular"));
const AuthorDetails = loadable(() => import("./authorDetails"));
const AdRightSticky = loadable(() => import("../components/ads/adRightSticky"));
const AdLeftSticky = loadable(() => import("../components/ads/adLeftSticky"));
const WhatNext = loadable(() => import("../components/whatNext"));
const WhatNextMobile = loadable(() => import("../components/whatNext/mobileRecommended"));
const AddThisPopular = loadable(() => import("../components/addthisPopular/addthis-popular"));
const FbComments = loadable(() => import("../components/fbComments"));
const Share = loadable(() => import("../components/share/sharing"));

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.data.datoCMS.article;
        this.url = `${metadata.url}/${props.pageContext.url}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.parent !== null);
        this.longAdCount = 0;

        this.getRecommendedPosts();

        this.schemaArticle = {
            '@context': 'https://schema.org',
            '@type': 'Article',
            author: {
                '@type': 'Person',
                name: this.data.author.name
            },
            datePublished: this.data.publishedAt,
            description: this.data.excerpt,
            headline: this.data.seo.title,
            url: this.url,
            name: this.data.seo.title,
            mainEntityOfPage: this.url,
            wordCount: this.props.pageContext.wordCount,
            articleSection: this.subCategory.title
        }
        if (this.data.seo.image) {
            this.schemaArticle.image = {
                '@type': 'ImageObject',
                url: this.data.seo.image.url,
            }
        }
    }

    getRecommendedPosts() {
        this.recommendedPosts = [];
        this.popularPosts = [];

        const slugs = [];
        let posts = [];
        if (this.props.data.datoCMS.article.tags !== "") {
            posts = posts
                .concat(shuffleArray(this.props.data.datoCMS.tags10))
                .concat(shuffleArray(this.props.data.datoCMS.tags9))
                .concat(shuffleArray(this.props.data.datoCMS.tags8))
                .concat(shuffleArray(this.props.data.datoCMS.tags7))
                .concat(shuffleArray(this.props.data.datoCMS.tags6))
                .concat(shuffleArray(this.props.data.datoCMS.tags5))
                .concat(shuffleArray(this.props.data.datoCMS.tags4))
                .concat(shuffleArray(this.props.data.datoCMS.threeTags))
                .concat(shuffleArray(this.props.data.datoCMS.twoTags))
                .concat(shuffleArray(this.props.data.datoCMS.oneTag))
        }
        posts = posts.concat(shuffleArray(this.props.data.datoCMS.sameCategory))
            .concat(shuffleArray(this.props.data.datoCMS.whatNextRandom));

        const articleCount = isMobile ? 0 : 2;

        for (let i = 0; i < posts.length && !isMobile; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.popularPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.popularPosts.length === 5) {
                break;
            }
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.recommendedPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.recommendedPosts.length === articleCount) {
                break;
            }
        }
    }

    replace_nth(s, f, r, n) {
        // From the given string s, replace f with r of nth occurrence
        return s.replace(RegExp("^(?:.*?" + f + "){" + n + "}"), x => x.replace(RegExp(f + "$"), r));
    };

    getContent() {
        let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
        // insert ads in HTML

        /*let contentArray = this.replace_nth(content, "</p>", "</p>$pension$", 4).split("$pension$");
        if (contentArray?.length > 1) {
            content = contentArray[0] + '<div id="apester-playlist-widget" style="min-height: 880px;"></div>' + contentArray[1];
        }*/

        let contentArray = this.replace_nth(content, "</p>", "</p>$pension$", 4).split("$pension$");
        if (contentArray?.length > 1) {
            content = contentArray[0] + '<div class="ad-container"><div class="advertisement-text">Advertisement</div><div id="div-pt_incontent1_mobile"></div><div id="div-pt_incontent1_desktop"></div></div>' + contentArray[1];
        }

        contentArray = this.replace_nth(content, "</p>", "</p>$pension$", 12).split("$pension$");
        if (contentArray?.length > 1) {
            content = contentArray[0] + '<div class="ad-container"><div class="advertisement-text">Advertisement</div><div id="div-pt_incontent2_mobile"></div><div id="div-pt_incontent2_desktop"></div></div>' + contentArray[1];
        }

        const lastPara = (content.match(/<\/p>/g) || []).length;
        const addLastAd = () => {
            /*contentArray = this.replace_nth(content, "</p>", "</p>$pension$", lastPara - 1).split("$pension$");
            if (contentArray?.length > 1) {
                content = contentArray[0] + '<div class="ad-container"><div class="advertisement-text">Advertisement</div><div id="div-pt_endcontent_mobile"></div><div id="div-pt_endcontent_desktop"></div></div>' + contentArray[1];
            }*/
        }

        if (lastPara >= 16) {
            for (let i = 16; i <= lastPara; i = i + 6) {
                contentArray = this.replace_nth(content, "</p>", "</p>$pension$", i).split("$pension$");
                if (contentArray?.length > 1) {
                    ++this.longAdCount;
                    content = contentArray[0] + `<div class="ad-container"><div class="advertisement-text">Advertisement</div><div id="div-pt_incontent_long${this.longAdCount}" class="long-ad-container"></div></div>` + contentArray[1];
                }
            }
            if (lastPara % 6) {
                addLastAd();
            }
        } else {
            lastPara > 13 && addLastAd();
        }

        content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");

        return content;
    }

    getUrlForFacebook() {
        if (isBrowser()) {
            return `${window.location.origin}/${this.props.pageContext.url}`;
        } else {
            return this.url;
        }
    }

    componentDidMount() {
        const adCount = this.longAdCount;

        setTimeout(() => {
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function () {
                try {
                    for (let i = 0; i < adCount; i++) {
                        const divId = `div-pt_incontent_long${i}`;
                        if (document.documentElement.clientWidth > 768) {
                            const slot = googletag.defineSlot('/22218985137/pensiontimes/pt_incontent2_desktop', [[300, 250], [336, 280]], divId);
                            slot && slot.addService(googletag.pubads());
                        } else {
                            const slot = googletag.defineSlot('/22218985137/pensiontimes/pt_incontent2_mobile', [300, 250], divId);
                            slot && slot.addService(googletag.pubads());
                        }
                    }
                    googletag.pubads().enableSingleRequest();
                    googletag.pubads().collapseEmptyDivs();
                    googletag.pubads().setCentering(true);
                    googletag.pubads().disableInitialLoad();
                    googletag.pubads().enableLazyLoad({
                        fetchMarginPercent: 200,
                        renderMarginPercent: 100,
                        mobileScaling: 2.0
                    });
                    googletag.enableServices();
                } catch (e) {
                    console.log(e);
                }
            });

            googletag.cmd.push(function () {
                googletag.display("div-pt_incontent1_mobile");
                googletag.display("div-pt_incontent1_desktop");
                googletag.display("div-pt_incontent2_mobile");
                googletag.display("div-pt_incontent2_desktop");
                /*googletag.display("div-pt_endcontent_mobile");
                googletag.display("div-pt_endcontent_desktop");*/
                for (let i = 0; i < adCount; i++) {
                    const divId = `div-pt_incontent_long${i}`;
                    googletag.display(divId);
                }
            });
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));

        const youtubeVideoDisplay = () => {
            const vidDefer = document.getElementsByTagName('iframe');
            for (let i = 0; i < vidDefer.length; i++) {
                if (vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
                }
            }
        }

        if (googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
            window.onload = () => {
                setTimeout(() => {
                    youtubeVideoDisplay();
                }, consts.SCRIPT_LOADING_TIME_WAIT * delayMultiplicationFactor(isMobile));
            };
        } else {
            setTimeout(() => {
                youtubeVideoDisplay();
            }, 1000 * delayMultiplicationFactor(isMobile));
        }
    }

    render() {
        return <Layout preloadImage={this.data.cover.responsiveImage}>
            <Helmet>
                <link rel="preload" as="image" href={this.data.cover.responsiveImage.src}
                      imagesrcset={this.data.cover.responsiveImage.webpSrcSet}
                      imagesizes={this.data.cover.responsiveImage.sizes}/>
                <meta name="keywords" content={this.data.tags}/>
                <script type="application/ld+json">{JSON.stringify(this.schemaArticle)}</script>
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper wrapper--article article">
                <AdLeftSticky/>
                <article className="article-content">
                    <div className={"category-heading-link"} data-datocms-noindex>
                        {this.subCategory.parent &&
                        <><Navigate
                            href={`/${this.subCategory.parent.slug}`}>{this.subCategory.parent.title}</Navigate><span> | </span></>}
                        <Navigate
                            href={`/${this.subCategory.parent.slug}/${this.subCategory.slug}`}>{this.subCategory.title}</Navigate>
                    </div>
                    <header className="article-content__header">
                        <div className="head">
                            <h1 className="head__title">{this.data.heading}</h1>
                            <p className="head__lead">{this.data.excerpt}</p>
                            {this.data.cover.url &&
                            <figure className="image">
                                <Image fadeIn="false" lazyLoad={false} fadeInDuration={10}
                                       data={this.data.cover.responsiveImage}/>
                            </figure>}
                            <div className="head__meta" data-datocms-noindex>
                                {this.data.author.profilePhoto && <div className="author-card__image">
                                    <img alt={this.data.author.name} width={80} height={80}
                                         src={`${this.data.author.profilePhoto?.url}?w=80&h=80&fit=crop&auto=compress`}/>
                                </div>}
                                <div>
                                    <div>
                                        By <Navigate
                                        href={`/authors/${this.data.author.slug}`}>{this.data.author.name}</Navigate>
                                    </div>
                                    <time>{formatDate(this.data.publishedAt)}</time>
                                    <span
                                        className={'reading-time'}> · {this.props.pageContext.readingTime} min read</span>
                                </div>
                            </div>
                            <Share/>
                            {this.data.json && this.data.json.faq &&
                            <Accordion data={this.data.json.faq}
                                       title={this.data.json.faqTitle ? this.data.json.faqTitle : `${this.data.heading}: Your questions answered`}></Accordion>}
                        </div>
                    </header>
                    <div className="article-content__body">
                        <main className="article-content__main" doc-container="main">
                            <div id="article-content" dangerouslySetInnerHTML={{__html: this.getContent()}}/>
                        </main>
                    </div>
                    <Share/>
                    <AuthorDetails author={this.data.author} deferImage={true} data-datocms-noindex/>
                    {this.subCategory.parent && this.subCategory.parent.slug === 'finance' && <Disclaimer/>}
                    <FbComments url={this.getUrlForFacebook()}/>
                    {!isMobile && <WhatNext recommendedPosts={this.recommendedPosts}/>}
                    {isMobile && <WhatNextMobile recommendedPosts={this.recommendedPosts}/>}
                    <AddThisPopular/>
                </article>
                {!isMobile && <ArticlePopularPosts articles={this.popularPosts}/>}
                <AdRightSticky/>
            </main>
        </Layout>
    }
}

export const query = graphql`
    query ArticleQuery($slug: String!, $tags10: String!, $tags9: String!, $tags8: String!, $tags7: String!, $tags6: String!, $tags5: String!, $tags4: String!, $tags3: String!, $tags2: String!, $tags1: String!, $category: [DatoCMS_ItemId], $videoCategories: [DatoCMS_ItemId]) {
        datoCMS {
            article(filter: {slug: {eq: $slug}}) {
                heading
                cover {
                    url
                    responsiveImage(
                        imgixParams: {auto: compress, fit: crop, w: 750, q: 40},
                        sizes: "50vw"
                    ) {
                        # HTML5 src/srcset/sizes attributes
                        srcSet
                        webpSrcSet
                        sizes
                        src

                        # size information (post-transformations)
                        width
                        height
                        aspectRatio

                        # SEO attributes
                        alt
                        title
                    }
                }
                content
                excerpt
                slug
                author {
                    name
                    bio
                    slug
                    profilePhoto {
                        url
                    }
                    linkedin
                    email
                }
                category {
                    slug
                    title
                    parent {
                        slug
                        title
                    }
                    position
                }
                publishedAt
                seo {
                    description
                    title
                    image {
                        url
                        width
                        height
                    }
                }
                tags
                json
            }
            tags10: allArticles(filter: {slug: {neq: $slug}, tags: {matches: {pattern: $tags10, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags9: allArticles(filter: {slug: {neq: $slug}, tags: {matches: {pattern: $tags9, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags8: allArticles(filter: {slug: {neq: $slug}, tags: {matches: {pattern: $tags8, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags7: allArticles(filter: {slug: {neq: $slug}, tags: {matches: {pattern: $tags7, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags6: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags6, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags5: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags5, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            tags4: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags4, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            threeTags: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags3, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            twoTags: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags2, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            oneTag: allArticles(filter: {slug: {neq: $slug}, category: {notIn: $videoCategories}, tags: {matches: {pattern: $tags1, regexp: true}}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
            sameCategory: allArticles(filter: {slug: {neq: $slug}, category: {anyIn: $category}}, orderBy: publishedAt_DESC, first: "4") {
                ...ArticleFieldsRecommended
            }
            whatNextRandom: allArticles(filter: {slug: {neq: $slug}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsRecommended
            }
        }
    }
`
export default Article
